<template>
	<Popup :title="'Vote for ' + props.serverTitle">
		<form class="flex flex-col gap-4">
			<HCaptcha />
			<FormGroup
					v-model:parent_ref="username"
					label="Username"
					autocomplete="username"
					placeholder="Username"
					:check="(value) => {
                            return value.length >= fieldLimits.username.min && value.length <= fieldLimits.username.max;
                        }"
			/>
			<div>
				<PopupButton text="Vote for this server" @click="vote"/>
			</div>
		</form>
	</Popup>
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";
import PopupButton from '~/components/popups/components/PopupButton.vue';

const { props, setPopup } = usePopup();

const user = useUser();

let localStorageUsername = null
try {
	localStorageUsername = localStorage.getItem("vote-username");
} catch (e) {
}

const username = ref(
	user.value ? user.value.username : localStorageUsername ? localStorageUsername : ""
);

async function vote() {
	const iframe = document.querySelector(".captcha iframe");
	if (!iframe) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	const captchaResponse = iframe.getAttribute("data-hcaptcha-response");

	if (!captchaResponse) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	localStorage.setItem("vote-username", username.value);

	notify({
		type: "info",
		title: "Voting..."
	})

	const server_id = useRoute().params.id.split("-")[1];

	const response = await $fetch("/api/server/" + server_id + "/vote", {
		method: "POST",
		body: {
			username: username.value,
			captcha: captchaResponse
		},
		ignoreResponseError: true
	});

	let closePopup = true;

	if (response.status) {
		notify({
			type: "success",
			title: "You have voted for this server!"
		})
	} else if (response.error) {
		if (response.error === "You have already voted for this server!") {
			const date = new Date();
			date.setUTCHours(0, 0, 0, 0);
			const localTimeString = new Intl.DateTimeFormat(undefined, {
				hour: "numeric",
				minute: "numeric"
			}).format(date);

			response.error += " You can vote again at " + localTimeString + "!";
		}

		notify({
			type: "error",
			title: response.error
		})
		if (response.error === "Please complete the captcha!") {
			closePopup = false;
		}
	} else {
		notify({
			type: "error",
			title: "Error occurred while voting!"
		})
	}

	if (closePopup) {
		setPopup(null);
	}
}
</script>